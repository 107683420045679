import React, { Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Header from "./components/Header";
import InitiativeDetails from "./views/initiative_details/InitiativeDetails";
import Homepage from "./views/initiative/Homepage";
import ScorecardMainPage from "./views/scorecard/ScorecardMainPage";
import FVIHomepage from "./views/financial_aoc_vs_initiative/FVIHomepage";
import MarginMapHome from "./views/margin-map/MarginMapHome";
import CreateOpportunity from "./views/opportunity/CreateOpportunity";
import MarginWalkPage from "./views/multi-year-margin-walk/MarginWalkPage";
import PlaygroundMainPage from "./views/playground/PlaygroundMainPage";
import MultiYearInitiativesByLever from "./views/multi-year-initiatives-by-lever/MultiYearInitiativesByLever";

const config = {
  clientId: process.env.REACT_APP_CLIENTID,
  issuer: process.env.REACT_APP_ISSUER,
  responseType: "id_token",
  redirectUri: `${window.location.origin}/callback`,
  scopes: ["openid", "profile", "email"],
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

function App() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log("restoreOriginalUri", originalUri, history);
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <div style={{ background: "#f7f9fc" }}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Header />
        <Suspense>
          <Switch>
            <SecureRoute
              exact
              path="/"
              render={(routeProps) => {
                return <Homepage props={routeProps} />;
              }}
            ></SecureRoute>
            <SecureRoute
              path="/initiative-details/:id/:sector/:driver/:lever/:year"
              exact={true}
              render={(routeProps) => {
                return <InitiativeDetails props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/scorecard"
              render={(routeProps) => {
                return <ScorecardMainPage props={routeProps} />;
              }}
            />
         <Route
            path="/playground"
            render={(routeProps) => {
              return <PlaygroundMainPage props={routeProps} />;
            }}
          />
            <SecureRoute
              path="/create-opportunity"
              render={(routeProps) => {
                return <CreateOpportunity props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/edit-opportunity/:id/:year"
              render={(routeProps) => {
                return <CreateOpportunity props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/margin-walk-future-years"
              render={(routeProps) => {
                return <MarginWalkPage props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/margin-map"
              render={(routeProps) => {
                return <MarginMapHome props={routeProps} />;
              }}
            />
            <SecureRoute
              path="/financial-aoc-vs-initiative-margin-walk"
              render={(routeProps) => {
                return <FVIHomepage props={routeProps} />;
              }}
            />
            <Route
              path="/multi-year-initiatives-by-lever"
              render={(routeProps) => {
                return <MultiYearInitiativesByLever props={routeProps} />;
              }}
            />
            <Route path="/callback" component={LoginCallback} />
          </Switch>
        </Suspense>
      </Security>
    </div>
  );
}

export default App;
